<template>
  <div class="card p-1">
    <!-- {{ this.detall }} -->
    <div class="custom-search">
      <div class="title head">
        <p class="h2" style="color: #558cef">ทะเบียนคุมทรัพย์สิน</p>
      </div>
      <hr />
      <!-- advance search input -->
      <div class="custom-search row">
        <b-form-group class="col-12 col-md-3">
          <label>ทะเบียนคุมทรัพย์สิน:</label>
          <b-input v-model="equipment" placeholder="ทะเบียนคุมทรัพย์สิน" />
        </b-form-group>
        <!-- <b-form-group class="col-12 col-md-3">
          <label>อายุการใช้งาน:</label>
          <b-input v-model="lifetime" placeholder="อายุการใช้งาน" />
        </b-form-group> -->
      </div>
      <div class="row justify-content-between">
        <div class="row col-6 mb-1">
          <div class="ml-1">
            <b-button variant="success" @click="get_table_report"> ค้นหา </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger" @click="cc_report"> ยกเลิก </b-button>
          </div>
          <!-- <div class="ml-1">
            <b-button variant="outline-primary" @click="exportExcel"> Export Excel </b-button>
          </div> -->
        </div>
        <div class="mr-2">
          <b-overlay :show="showpdf" rounded="sm">
            <b-button variant="outline-primary" @click="exportpdf">
              พิมพ์รายงานทะเบียนคุมทรัพย์สินทั้งหมด PDF
            </b-button></b-overlay
          >
        </div>
      </div>
    </div>

    <!-- table -->
    <b-overlay :show="show" rounded="sm">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        theme="my-theme"
        :line-numbers="true"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'in_information'" class="text-nowrap">
            <div v-if="props.row.flag_calculate === 'true'">
              <b-button :to="{ name: 'controlregistrationview', params: { id: props.row } }">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </div>
            <div v-else>
              <b-button
                class="btn-danger"
                :to="{ name: 'controlregistrationview', params: { id: props.row } }"
                disabled
              >
                ไม่คำนวนค่าเสือม
              </b-button>
            </div>
          </span>
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span>{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'listmenu'">
            <span>
              <b-button variant="outline-warning" :to="{ name: 'inseakuruplun', params: { id: props.row } }">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </span>
          </span>
          <span v-else-if="props.column.field === 'จำนวน'">
            <div class="text-right">{{}}</div>
          </span>
          <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
            <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <div v-if="props.row.status === 'รับเข้า'">
              <b-button variant="info">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'เบิก'">
              <b-button variant="warning">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'ยืม'">
              <b-button variant="danger">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'คืน'">
              <b-button variant="success">
                {{ props.row.status }}
              </b-button>
            </div>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import _ from "lodash";
import { async } from "q";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  data() {
    return {
      date: {
        startDate: "",
        endDate: "",
      },
      selected: null,
      options: [{ value: null, text: "เลือกปีงบประมาณ" }],
      equipment_code: "",
      e_number: "",
      serial_number: "",
      e_name: "",
      lifetime: "",
      currentPage: 1,
      pageLength: 100,
      total: "",
      show: false,
      showpdf: false,
      dir: false,
      workgroup: null,
      workgroups: [],
      equipmentGroup: null,
      equipmentGroups: [],
      equipment: "",
      columns: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
          width: "60px",
        },

        {
          label: "Serial Number",
          field: "serial_number",
          width: "50px",
        },
        {
          label: "รายละเอียด",
          field: "information",
          width: "500px",
        },
        {
          label: "ทะเบียนคุมทรัพย์สิน",
          field: "in_information",
          width: "50px",
        },
      ],
      detall: {},
      rows: [],

      searchTerm: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      get_arr: [],
      arr_a: [],
      contentData: [],
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    const y = new Date().getFullYear();
    const yy = y + 543;
    this.options.push({ value: yy, text: yy });
    for (let index = 1; index < 5; index++) {
      this.options.push({ value: yy - index, text: yy - index });
    }
    this.workgroups_table();
    this.equipment_table();
    // this.checkLogout();
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },

    async exportpdf() {
      // this.rows = res.data.message.data;

      const { access_token } = await this.access_token();

      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const ddr = this.rows.filter((eel) => {
        if (eel.flag_calculate === "true") {
          this.showpdf = true;
          return {
            ...eel,
          };
        } else {
          this.showpdf = false;
          return;
        }
      });
      this.showpdf = true;
      console.log(ddr);
      if (ddr.length == 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `ไม่พบข้อมูล ทะเบียนคุมทรัพย์สิน ${this.equipment}`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.showpdf = false;
        return;
      }

      const res_e_number = ddr;
      const [e_number] = ddr;
      console.log(res_e_number);
      console.log(e_number);

      const url_life = `${API}lifetimeLists?e_number=${e_number.e_number}`;
      const res_life = await axios.get(url_life, head);

      //   console.log(res_life.data.message.data);
      const [lifetime] = res_life.data.message.data;
      const header_datas = [];
      const content_datas = [];
      const sum_datas = [];
      for (let i in res_e_number) {
        const url1 = `${API}propertyRegistration?e_number=${res_e_number[i].e_number}&lifetime=${
          lifetime.lifetime_new ? lifetime.lifetime_new : lifetime.lifetime_old
        }&refkey=${res_e_number[i].refkey}`;
        const url2 = `${API}headerPropertyRegistration?e_number=${res_e_number[i].e_number}`;
        // console.log(url1)
        const content_data = await axios.get(url1, head);
        const header_data = await axios.get(url2, head);
        console.log(content_data.data.message.data);
        header_datas.push(header_data.data.message.data);
        content_datas.push(content_data.data.message.data);
        // sum_datas.push(content_data.data.message.sum);
      }

      console.log(header_datas);
      console.log(content_datas);
      console.log(sum_datas);
      console.log("---------------------------------------------------");

      const ooo = [];
      header_datas.map((res, index, arr) => {
        ooo.push({
          head: res,
          content: content_datas[index],
        });
        // ooo[index].content.push({
        //   rownum: '',
        //   admitdate: '',
        //   equipment_code: '',
        //   e_name: '',
        //   information: '',
        //   unittype: '',
        //   unitprice: '',
        //   expire: '',
        //   อัตราค่าเสื่อมราคาต่อปี: '',
        //   อัตราค่าเสื่อมราคาต่อวัน: '',
        //   ค่าเสื่อมราคาประจำปี: '',
        //   ค่าเสื่อมราคาสะสม: '',
        //   มูลค่าสุทธิ: '',
        //   e_amortization: '',
        //   daycount: '',
        //   lifetime_new: '',
        // });
      });

      console.log(ooo);

      const result = ooo.map((res, index) => {
        console.log(res.content);

        return {
          [Object.keys(res)[0]]: res[Object.keys(res)[0]],
          [Object.keys(res)[1]]: res[Object.keys(res)[1]].map((res2, index, arr) => {
            let result = [
              { text: res2.rownum, style: "text_center" },
              {
                text: `${parseInt(res2.admitdate.split("-")[2])} ${
                  this.month[parseInt(res2.admitdate.split("-")[1])]
                } ${Number(res2.admitdate.split("-")[0]) + 543}`,
                style: "text_left",
              },
              { text: res2.equipment_code, style: "text_left" },
              { text: res2.information, style: "text_left" },
              { text: res2.unittype, style: "text_left" },
              {
                text: _.isEmpty(res2.unitprice)
                  ? res2.unitprice
                  : Number(res2.unitprice)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                style: "text_right",
                alignment: "right",
              },
              {
                text: _.isEmpty(res2.unitprice)
                  ? res2.unitprice
                  : Number(res2.unitprice)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                style: "text_right",
                alignment: "right",
              },
              { text: res2.e_name === undefined ? "" : res2.lifetime_new, style: "text_right", alignment: "right" },
              { text: res2.daycount ? Number(res2.daycount) : "", style: "text_right", alignment: "right" },
              {
                text: _.isEmpty(res2["อัตราค่าเสื่อมราคาต่อปี"])
                  ? res2["อัตราค่าเสื่อมราคาต่อปี"]
                  : Number(res2["อัตราค่าเสื่อมราคาต่อปี"])
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                style: "text_right",
                alignment: "right",
              },
              {
                text: _.isEmpty(res2["ค่าเสื่อมราคาประจำปี"])
                  ? res2["ค่าเสื่อมราคาประจำปี"]
                  : Number(res2["ค่าเสื่อมราคาประจำปี"])
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),

                style: "text_right",
                alignment: "right",
              },
              {
                text: _.isEmpty(res2["ค่าเสื่อมราคาสะสม"])
                  ? res2["ค่าเสื่อมราคาสะสม"]
                  : Number(res2["ค่าเสื่อมราคาสะสม"])
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),

                style: "text_right",
                alignment: "right",
              },
              {
                text: _.isEmpty(res2["มูลค่าสุทธิ"])
                  ? res2["มูลค่าสุทธิ"]
                  : Number(res2["มูลค่าสุทธิ"])
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                style: "text_right",
                alignment: "right",
              },
            ];
            //แปลงตัวเลขให้เป็น string ใส่ , และทำให้เหลือ 2 ตำแหน่ง
            return result;
            // .map((res, index) => {
            //   const noFn = [];
            //   console.log(res, index);
            //   if (index == 9) {
            //     return typeof res.text === 'number' && !noFn.includes(index)
            //       ? res.text
            //       : res;
            //   } else {
            //     return typeof res.text === 'number' && !noFn.includes(index)
            //       ? res.text
            //           .toFixed(2)
            //           .toString()
            //           .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
            //       : res;
            //   }
            // });
          }),
        };
      });

      const contentData = result.map((res, idx, arr) => {
        const inHead = [
          {
            text: `ประเภท: ${res.head[0].e_name} หมายเลขทรัพย์สิน: ${res.head[0].e_number} รายละเอียด: ${res.head[0].information}`,
            style: "text",
            pageOrientation: "landscape",
          },
          {
            text: `สถานที่ตั้ง/หน่วยงานที่รับผิดชอบ: ${
              res.head[0].work_gname2 ? res.head[0].work_gname2 : res.head[0].work_gname
            } ผู้เบิก: ${
              res.head[0].withdraw_person ? res.head[0].withdraw_person : res.head[0].response_person
            } ผู้รับผิดฃอบ: ${res.head[0].responsename} สถานที่จัดเก็บ: ${res.head[0].place}`,
            style: "text",
            pageOrientation: "landscape",
          },
          {
            text: `ชื่อผู้ขาย/ผู้รับจ้าง/ผู้บริจาค: ${res.head[0].vender} อ้างอิงสัญญาฯ สัญญาเลขที่: ${
              _.isEmpty(res.head[0].important_number) ? "" : res.head[0].important_number
            } วันที่: ${_.isEmpty(res.head[0].important_date) ? "" : res.head[0].important_date} `,
            style: "text",
            pageOrientation: "landscape",
          },
          {
            text: `เลขที่ใบส่งของ: ${_.isEmpty(res.head[0].invoice_number) ? "" : res.head[0].invoice_number} วันที่: ${
              _.isEmpty(res.head[0].invoice_date) ? "" : res.head[0].invoice_date
            } ที่อยู่: ${_.isEmpty(res.head[0].vender_address) ? "" : res.head[0].vender_address} ประเภทเงิน: ${
              res.head[0].budget_type
            } วิธีการได้มา: ${res.head[0].supply_by}`,
            style: "text",
            pageOrientation: "landscape",
          },
        ];

        return [
          idx !== arr.length ? inHead : {},
          {
            style: "tableHeader",
            table: {
              headerRows: 1,
              widths: [
                "auto",
                "auto",
                "auto",
                "*",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
              ],
              body: [
                [
                  {
                    text: `ปีที่`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `วัน-เดือน-ปี`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ที่เอกสาร`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `รายการ`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `จำนวนหน่วย`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ราคาต่อหน่วย`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `มูลค่ารวม`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `อายุการใช้งาน`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `จำนวนวัน`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `อัตราค่าเสื่อม (%)`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ค่าเสื่อมประจำปี`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ค่าเสื่อมราคาสะสม`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `มูลค่าสุทธิ`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],
                ...res.content,
              ],
            },
          },
          idx !== arr.length - 1
            ? {
                text: ``,
                pageOrientation: "landscape",
                pageBreak: "before",
              }
            : {},
        ];
      });
      this.contentData = contentData;
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 80, 10, 50],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "ทะเบียนคุมทรัพย์สิน",
        },
        header: [
          {
            text: "ทะเบียนคุมทรัพย์สิน",
            alignment: "center",
            margin: [0, 15, 0, 0],
          },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            alignment: "center",
            margin: [0, 0, 0, 0],
          },
        ],

        content: this.contentData,
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 0, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 8,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 0, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            bold: false,
            fontSize: 6,
          },
          text_right: {
            alignment: "right",
            bold: false,
            fontSize: 6,
          },
          text_left: {
            alignment: "left",
            fontSize: 6,
          },
          text: {
            fontSize: 7,
          },
        },
      };
      this.showpdf = false;
      pdfMake.createPdf(docDefinition).download(`ทะเบียนคุมทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "ทะเบียนคุมทรัพย์สิน",
        columns: [
          {
            title: `ทะเบียนคุมทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: `ประเภท : ${this.detall.e_name} หมายเลขทรัพย์สิน: ${this.detall.e_number} รายละเอียด : ${this.detall.information}`,
                headerCellOptions: { textAlign: "" },
                children: [
                  {
                    title: `สถานที่ตั้ง/หน่วยงานที่รับผิดชอบ : ${
                      this.detall.work_gname2 ? this.detall.work_gname2 : this.detall.work_gname
                    } ผู้เบิก: ${
                      this.detall.withdraw_person ? this.detall.withdraw_person : this.detall.response_person
                    } ผู้รับผิดฃอบ : ${this.detall.responsename} สถานที่จัดเก็บ : ${this.detall.place}`,
                    headerCellOptions: { textAlign: "" },
                    children: [
                      {
                        title: `ชื่อผู้ขาย/ผู้รับจ้าง/ผู้บริจาค : ${this.detall.vender} อ้างอิงสัญญาฯ สัญญาเลขที่: ${
                          this.detall.important_number == null ? "" : this.detall.important_number
                        } วันที่ : ${
                          this.detall.important_date_th == undefined ? "" : this.detall.important_date_th
                        } เลขที่ใบส่งของ : ${
                          this.detall.invoice_number == null ? "" : this.detall.invoice_number
                        } วันที่ : ${this.detall.invoice_date_th == undefined ? "" : this.detall.invoice_date_th}`,
                        headerCellOptions: { textAlign: "" },
                        children: [
                          {
                            title: `ที่อยู่ : ${this.detall.vender_address} `,
                            headerCellOptions: { textAlign: "" },
                            children: [
                              {
                                title: `ประเภทเงิน: ${this.detall.budget_type} วิธีการได้มา : ${this.detall.supply_by}`,
                                headerCellOptions: { textAlign: "" },
                                children: [
                                  {
                                    title: "ปีที่",
                                    field: "rownum",
                                  },
                                  {
                                    title: "วัน-เดือน-ปี",
                                    field: "admitdate_th",
                                  },
                                  {
                                    title: "ที่เอกสาร",
                                    field: "equipment_code",
                                  },
                                  {
                                    title: "รายการ",
                                    field: "information",
                                  },
                                  {
                                    title: "จำนวนหน่วย",
                                    field: "unittype",
                                  },
                                  {
                                    title: "ราคาต่อหน่วย",
                                    field: "unitprice",
                                  },
                                  {
                                    title: "มูลค่ารวม",
                                    field: "unitprice",
                                  },
                                  {
                                    title: "อายุการใช้งาน",
                                    field: "expire",
                                  },
                                  {
                                    title: "จำนวนวัน",
                                    field: "daycount",
                                  },
                                  {
                                    title: "อัตราค่าเสื่อม (%)",
                                    field: "อัตราค่าเสื่อมราคาต่อปี",
                                  },
                                  {
                                    title: "ค่าเสื่อมประจำปี",
                                    field: "ค่าเสื่อมราคาประจำปี_ex",
                                  },
                                  {
                                    title: "ค่าเสื่อมราคาสะสม",
                                    field: "ค่าเสื่อมราคาสะสม_ex",
                                  },
                                  {
                                    title: "มูลค่าสุทธิ",
                                    field: "มูลค่าสุทธิ_ex",
                                  },
                                ],
                              },
                              {
                                title: ``,
                                headerCellOptions: { textAlign: "center" },
                                children: [],
                              },
                            ],
                          },
                          {
                            title: ``,
                            headerCellOptions: { textAlign: "center" },
                            children: [],
                          },
                        ],
                      },
                      {
                        title: ``,
                        headerCellOptions: { textAlign: "center" },
                        children: [],
                      },
                    ],
                  },
                  {
                    title: ``,
                    headerCellOptions: { textAlign: "center" },
                    children: [],
                  },
                ],
              },
              {
                title: ``,
                headerCellOptions: { textAlign: "center" },
                children: [],
              },
            ],
          },
        ],
      });
    },
    async getdata() {
      const { access_token } = await this.access_token();

      const urlmain = `${API}inventory?_sort=-1&_limit=${this.pageLength}&_page=${this.currentPage}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      let resmain = await axios.get(urlmain, head);
      console.log(resmain.data.message.total);
      let idx1 = Math.ceil(Number(resmain.data.message.total) / 100);
      const data1 = [];
      for (let index = 1; index <= idx1; index++) {
        console.log(index);
        const url1 = `${API}inventory?_sort=-1&_limit=${this.pageLength}&_page=${index}`;
        const res1 = await axios.get(url1, head);
        data1.push(...res1.data.message.data);
        console.log(res1.data.message.data);
      }
      let res_data = [];

      data1.forEach(async (element) => {
        // console.log(element);
        if (this.equipment === element.equipment_code) {
          // console.log(this.equipment)
          //console.log(element.equipment_code);
          const url = `${API}inventoryList?equipment_code=${element.equipment_code}&e_id=${element.e_id}&_limit=${this.pageLength}&refkey=${element.refkey}&_page=${this.currentPage}&_sort=-1`;
          const res = await axios.get(url, head);

          let idx = Math.ceil(Number(res.data.message.total) / 100);
          console.log(idx);
          for (let index = 1; index <= idx; index++) {
            console.log(index);
            const url2 = `${API}inventoryList?equipment_code=${element.equipment_code}&e_id=${element.e_id}&_limit=${this.pageLength}&refkey=${element.refkey}&_page=${index}&_sort=-1`;
            const res2 = await axios.get(url2, head);
            res_data.push(...res2.data.message.data);
          }
        }
        //ตร-003-60
        this.total = await res_data.length;
        this.rows = await res_data;
        this.show = false;
      });

      // const [fmaindata] = resmain.filter((res) => res.equipment_code === this.equipment);
      //   console.log(fmaindata);
    },
    async get_table_report() {
      if (_.isEmpty(this.equipment)) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `กรุณากรอกข้อมูลทะเบียนคุมทรัพย์สิน`,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      this.show = true;
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      console.log(this.get_arr);
      this.getdata();
      // console.log(res_life.data.message.data[0].lifetime_new);
      // const url = `${API}propertyRegistration?e_number=${this.equipment}&lifetime=${res_life.data.message.data[0].lifetime_new}`;
      // 02-011-0001/65
      //   const res = await axios.get(url, header);
      //   console.log(res.data.message.data);
      // console.log(
      //   res.data.message.sum.map((el) => {
      //     return {
      //       ...el,
      //       ค่าเสื่อมราคาประจำปี: `${el.ค่าเสื่อมราคาประจำปี}`,
      //       ค่าเสื่อมราคาสะสม: `${el.ค่าเสื่อมราคาสะสม}`,
      //       มูลค่าสุทธิ: `${el.มูลค่าสุทธิ}`,
      //     };
      //   })
      // );
      //01-036-0004/65

      //   this.rows = await res.data.message.data.map((element) => {
      //     return {
      //       ...element,
      //       admitdate: element.admitdate,
      //       admitdate_th: `${parseInt(element.admitdate.split('-')[2])} ${this.month[parseInt(element.admitdate.split('-')[1])]} ${
      //         Number(element.admitdate.split('-')[0]) + 543
      //       }`,
      //       ค่าเสื่อมราคาประจำปี_ex: Number(element.ค่าเสื่อมราคาประจำปี),
      //       ค่าเสื่อมราคาสะสม_ex: Number(element.ค่าเสื่อมราคาสะสม),
      //       มูลค่าสุทธิ_ex: Number(element.มูลค่าสุทธิ),
      //     };
      //   });
      //   this.rows = this.rows.concat(res.data.message.sum);

      // console.log(
      //   res.data.message.data.map((element) => {
      //     return {
      //       ...element,
      //       admitdate: element.admitdate,
      //       admitdate_th: `${parseInt(element.admitdate.split('-')[2])} ${
      //         this.month[parseInt(element.admitdate.split('-')[1])]
      //       } ${Number(element.admitdate.split('-')[0]) + 543}`,
      //     };
      //   })
      // );
    },
    formatFn: function (value) {
      return Number(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    cc_report() {
      this.rows = [];
      this.date.startDate = "";
      this.date.endDate = "";
      this.equipmentGroup = null;
    },
    async equipment_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}equipmentGroups?_page=1&_limit=100`;
      const res = await axios.get(url, header);

      this.equipmentGroups = res.data.message.data.map((element) => {
        return {
          ...element,
          e_gid_e_gname: `${element.e_gid} - ${element.e_gname}`,
        };
      });
    },
    async workgroups_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const res = await axios.get(url, header);
      this.workgroups = res.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
